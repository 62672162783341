import { Component, inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { maxDateValidator, minDateValidator } from '@ui/legacy-lib';
import {
  aesItpStateValidator,
  aesSchufaStateValidator,
  aesWorkflowStateValidator
} from '@ui/legacy-lib';
import { ModalService } from '@ui/legacy-lib';
import { DigitalContractFormService } from '@ui/legacy-lib';
import { DigitalContractService } from '@ui/legacy-lib';
import {
  getCookiesPreference,
  InitCookiesPreference,
  LocalStorageService,
  OpenCookieSettingsModal,
  SetCookiesPreference,
  WINDOW_REF
} from '@ui/legacy-lib';

import { ThemeService } from '@ui/legacy-lib';
import {
  CookiePreference,
  CurrentState,
  DigitalContract,
  DigitalContractItpState,
  DigitalContractSignerState,
  DigitalContractWorkflowState,
  DocuSignEvent,
  DocuSignResponse,
  Person,
  QesMethods,
  SchufaVerificationState,
  SignatureType,
  User
} from '@ui/shared/models';

import moment from 'moment';

import { ibanValidator } from 'ngx-iban';

import { combineLatest, Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import * as fromAppState from 'tenant-pool/+state';

import { dialogConfig, storageKeys } from 'tenant-pool/config';

import { ENVIRONMENT_CONFIG } from 'tenant-pool/core';
import { ConfirmIbanModalComponent } from 'tenant-pool/components/digital-contract/components/confirm-iban-modal/confirm-iban-modal.component';
import { ContractHelpModalComponent } from 'tenant-pool/components/digital-contract/components/contract-help-modal/contract-help-modal.component';
import { ActionState } from '@ui/legacy-lib';
import { AsyncPipe } from '@angular/common';
import { SvgIconComponent } from 'angular-svg-icon';
import { ComponentsModule } from '@ui/legacy-lib';
import {
  QualifiedElectronicSignatureComponent,
  QualifiedElectronicSignatureConfirmTermsAndConditionsComponent
} from '@ui/legacy-lib';
import * as fromState from '../../../+state';

import {
  DigitalContractWizardSteps,
  SignContractStepsRoutes
} from '../sign-contract.childRoutes';
import { ContractSigningComponent } from '../components/contract-signing/contract-signing.component';
import { ContractCodeVerificationComponent } from '../components/contract-code-verification/contract-code-verification.component';
import { ContractIdVerificationComponent } from '../components/contract-id-verification/contract-id-verification.component';
import { ContractViewingComponent } from '../components/contract-viewing/contract-viewing.component';
import { ContractPersonalInformationComponent } from '../components/contract-personal-information/contract-personal-information.component';

@UntilDestroy()
@Component({
  selector: 'app-sign-contract-wizard',
  templateUrl: './sign-contract.component.html',
  styleUrls: ['./sign-contract.component.scss'],
  standalone: true,
  imports: [
    ComponentsModule,
    SvgIconComponent,
    ContractPersonalInformationComponent,
    ContractViewingComponent,
    ContractIdVerificationComponent,
    ContractCodeVerificationComponent,
    ContractSigningComponent,
    QualifiedElectronicSignatureComponent,
    QualifiedElectronicSignatureConfirmTermsAndConditionsComponent,
    TranslateModule,
    AsyncPipe
  ]
})
export class SignContractComponent implements OnInit {
  private fb = inject(FormBuilder);
  private store = inject(Store);
  private modalService = inject(ModalService);
  private route = inject(ActivatedRoute);
  private localStorageService = inject(LocalStorageService);
  private formService = inject(DigitalContractFormService);
  private dmvService = inject(DigitalContractService);
  private themeService = inject(ThemeService);
  private translate = inject(TranslateService);
  private windowRef = inject(WINDOW_REF);
  private environment = inject(ENVIRONMENT_CONFIG);

  public qesActionState$: Observable<ActionState>;
  public qesLink$: Observable<string>;
  public allSteps: { name: string; nameNice: string }[] = [];
  public currentStepIndex: number;
  public processing$: Observable<boolean>;
  public isErrorSendingTenantData$: Observable<boolean>;
  public showShopCard: boolean;
  public form: FormGroup;
  public currentForm: FormGroup;
  public contactPerson: Person;
  public user: User;

  public continueContractWhenNotVisitedFlat: boolean;
  public isRedirectedFromDocuSign = false;

  public VERIFICATION_CODE_LENGTH = 8;

  public redirectUrl: string;

  public contractSignatureType: SignatureType;
  public confirmAesModalOpen: boolean;

  public contract: DigitalContract;
  public cookiesPreference: CookiePreference;

  public maxDate: NgbDateStruct = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date()
  };
  public minDate: NgbDateStruct = {
    year: moment().year() - 10,
    month: moment().month(),
    day: moment().date()
  };
  private token: string;
  private signerState: DigitalContractSignerState;

  public get flatVisitedUpdated() {
    return (
      this.signerState ===
      DigitalContractSignerState.INTERNAL_FLAT_VISITED_UPDATED
    );
  }

  public get hasContact() {
    return (
      this.contactPerson?.lastname !== '' ||
      this.contactPerson?.firstname !== '' ||
      this.contactPerson?.email !== '' ||
      this.contactPerson?.phone !== ''
    );
  }

  public get personalInformationForm() {
    return this.form.get('personalInformation') as FormGroup;
  }

  public get viewingForm() {
    return this.form.get('viewing') as FormGroup;
  }

  public get idVerificationForm() {
    return this.form.get('idVerification') as FormGroup;
  }

  public get codeVerificationForm() {
    return this.form.get('codeVerification') as FormGroup;
  }

  public get signingForm() {
    return this.form.get('signing') as FormGroup;
  }

  public get getIban() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-call
    return (this.idVerificationForm.get('iban').value || '')
      .toUpperCase()
      .replace(/\s/g, '');
  }

  public get getDateOfBirth() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return this.idVerificationForm.get('dateOfBirth').value;
  }

  ngOnInit(): void {
    this.store
      .select(fromAppState.getDigitalContract)
      .pipe(untilDestroyed(this))
      .subscribe(contractData => (this.contract = contractData));

    const minDate = moment({
      ...this.minDate,
      month: this.minDate.month - 1 // JAN: 1, but in moment JAN: 0
    }).toDate();
    const maxDate = moment({
      ...this.maxDate,
      month: this.maxDate.month - 1 // JAN: 1, but in moment JAN: 0
    }).toDate();

    this.form = this.fb.group({
      personalInformation: this.fb.group({
        tenantSigners: this.fb.array([]),
        confirmData: [false, Validators.requiredTrue],
        messageLandlord: ['', Validators.maxLength(2048)]
      }),
      viewing: this.fb.group({
        visited: [null, Validators.required],
        confirmViewing: [null, Validators.requiredTrue],
        date: [
          null,
          Validators.compose([
            minDateValidator(minDate),
            maxDateValidator(maxDate),
            Validators.required
          ])
        ]
      }),
      idVerification: this.fb.group({
        iban: [
          null,
          Validators.compose([Validators.required, ibanValidator()])
        ],
        dateOfBirth: [null, Validators.compose([Validators.required])],
        aesSchufaState: [null, Validators.compose([aesSchufaStateValidator])],
        aesItpState: [null, Validators.compose([aesItpStateValidator])]
      }),
      codeVerification: this.fb.group({
        aesCodeInput: [
          null,
          Validators.compose([
            Validators.minLength(this.VERIFICATION_CODE_LENGTH),
            Validators.maxLength(this.VERIFICATION_CODE_LENGTH),
            Validators.required
          ])
        ],
        aesWorkflowState: [
          null,
          Validators.compose([aesWorkflowStateValidator])
        ]
      }),
      signing: this.fb.group({
        isRedirectedFromDocuSign: [false, Validators.requiredTrue]
      })
    });

    const params = { ...this.route.snapshot.queryParams };
    const contract = this.localStorageService.getItem(
      storageKeys.digitalContract
    );
    const tenantSigners = [];

    this.allSteps = this.getAllSteps(
      contract?.signatureType || SignatureType.AES_MAIL
    );

    if (contract) {
      if (contract.branding) {
        this.themeService.createTheme(contract.branding);
      }
      this.redirectUrl =
        contract.successRedirectUrl ||
        `${this.environment.property_searcher_base_url}/${this.translate.currentLang}/digitalContract/signing`;
      this.showShopCard = contract.branding?.itpSettings?.shopCard;
      this.continueContractWhenNotVisitedFlat =
        contract.continueContractWhenNotVisitedFlat;
      this.token = contract.token;
      this.contactPerson = contract.contactInfo;
      this.contractSignatureType = contract.signatureType;
      tenantSigners.push(...contract.tenantSigners);
      if (tenantSigners.length === 1) {
        this.user = this.dmvService.getUser(contract.tenantSigners[0]);
        // initially load the preference from LS and enable tracking if configured
        this.store.dispatch(new InitCookiesPreference(this.user));
      }
    }

    this.store
      .select(getCookiesPreference)
      .pipe(
        filter(cookiesPreference => !!cookiesPreference),
        untilDestroyed(this)
      )
      .subscribe(
        cookiesPreference => (this.cookiesPreference = cookiesPreference)
      );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (params?.event?.toUpperCase() === DocuSignEvent.SIGNING_COMPLETE) {
      this.isRedirectedFromDocuSign = true;
      this.localStorageService.removeItem(storageKeys.digitalContract);
      this.store.dispatch(
        new fromState.DigitalContractWizardGoToStep(this.allSteps.length)
      );
    } else {
      this.store.dispatch(new fromState.GetCurrentState(this.token));

      this.store
        .select(fromState.getAesCheckState)
        .pipe(
          filter(state => !!state),
          untilDestroyed(this)
        )
        .subscribe((aesState: CurrentState) =>
          this.handleAesState(aesState, this.contractSignatureType)
        );
    }

    this.store
      .select(fromState.getContractWizardStepNumber)
      .pipe(untilDestroyed(this))
      .subscribe((currentStepIndex: number) => {
        this.currentStepIndex = currentStepIndex;
        this.currentForm = this.form.get(
          this.allSteps[currentStepIndex - 1].name
        ) as FormGroup;
      });

    this.form.patchValue({
      signing: {
        isRedirectedFromDocuSign: this.isRedirectedFromDocuSign
      },
      idVerification: {
        dateOfBirth: this.getBirthday(tenantSigners)
      },
      codeVerification: {
        aesCode: []
      }
    });

    this.formService.patchSigners({
      signers: tenantSigners,
      formArray: this.form
        .get('personalInformation')
        .get('tenantSigners') as FormArray,
      isAddressRequired: true
    });

    this.store
      .select(fromState.getDocuSignResponse)
      .pipe(
        filter(response => !!response),
        untilDestroyed(this)
      )
      .subscribe((docuSignResponse: DocuSignResponse) =>
        this.handleDocusignResponse(
          docuSignResponse.workflowState,
          docuSignResponse.embeddedUrl
        )
      );

    this.qesActionState$ = this.store.select(fromState.getQesActionState);
    this.qesLink$ = this.store.select(fromState.getQesLink);

    this.processing$ = combineLatest([
      this.store.select(fromState.getMessageSending),
      this.store.select(fromState.getSigningUrlLoading),
      this.store.select(fromState.getTenantInformationLoading),
      this.store.select(fromState.getAesStatusLoading)
    ]).pipe(map(array => array.some(pending => pending)));

    this.isErrorSendingTenantData$ = this.store
      .select(fromState.getTenantInformationActionState)
      .pipe(map(actionState => !!actionState.error));
  }

  previousStep() {
    if (this.isRedirectedFromDocuSign || this.currentStepIndex === 1) return;

    this.store.dispatch(new fromState.DigitalContractWizardPreviousStep());
  }

  nextStep(stepName: string) {
    this.store.dispatch(new fromState.DigitalContractWizardNextStep(stepName));
  }

  nextStepAfterPersonalInfo(stepName: string) {
    if (this.contract.signatureType === SignatureType.AES_SMS) {
      this.modalService
        .openConfirmation({
          data: {
            acknowledge: true,
            message: 'digital_contract.aes_sms_message_l',
            titleMessage: 'digital_contract.aes_sms_title_l'
          }
        })
        .onClose()
        .subscribe(() => {
          this.nextStep(stepName);
        });
    } else {
      this.nextStep(stepName);
    }
  }

  /**
   * After viewing we always call the endpoint and will receive a DocuSignResponse. We select the result
   * and will then call the correct wizard step according to the workflowState.
   * @param stepName
   */
  nextStepAfterViewing(stepName: string) {
    const { visited, date } = this.viewingForm.value;

    if (this.flatVisitedUpdated) {
      this.nextStep(stepName);
    } else if (visited || this.continueContractWhenNotVisitedFlat) {
      this.sendTenantInfo(true, date);
    } else {
      const options = { data: dialogConfig.digitalContract.cancel };

      this.modalService
        .openConfirmation(options)
        .onClose()
        .subscribe(() => {
          this.sendTenantInfo(false);
          this.store
            .select(fromState.getTenantInformationActionState)
            .pipe(
              filter(state => state.done),
              untilDestroyed(this)
            )
            .subscribe(() => {
              this.redirectToLandingPage();
            });
        });
    }
  }

  completeSigning() {
    this.redirectHome();
  }

  /*
   * Cancel contract
   */
  public sendMessageToLandlord() {
    const options = {
      data: dialogConfig.digitalContract.sendMessage
    };
    this.modalService
      .openConfirmation(options)
      .onClose()
      .subscribe(() => {
        this.store.dispatch(new fromState.SendMessage(this.token));
        this.store
          .select(fromState.getMessageSending)
          .pipe(
            filter(pending => !pending),
            untilDestroyed(this)
          )
          .subscribe(() => {
            this.redirectToLandingPage();
          });
      });
  }

  public openHelp() {
    this.modalService.open<ContractHelpModalComponent>(
      ContractHelpModalComponent,
      {
        data: {
          contact: this.contactPerson,
          isStepCode:
            this.currentStepIndex ===
            this.getWizardStepIndex(
              DigitalContractWizardSteps.CODE_VERIFICATION
            ),
          customCookieSettings: () =>
            this.customCookieSettings(this.cookiesPreference)
        }
      }
    );
  }

  public getStepIndex(name: string) {
    return this.allSteps ? this.allSteps.map(el => el.name).indexOf(name) : -1;
  }

  /**
   * Wizard step starts at 1 - internal steps start at 0
   */
  public getWizardStepIndex(name: string) {
    const index = this.getStepIndex(name);
    return index > -1 ? index + 1 : -1;
  }

  public getStepName(index: number) {
    const normalizedIndex = --index;
    return this.allSteps[normalizedIndex]
      ? this.allSteps[normalizedIndex].name
      : null;
  }

  public showStep(name: string) {
    return this.getStepIndex(name) > -1;
  }

  public acceptCookies(payload: CookiePreference) {
    this.store.dispatch(new SetCookiesPreference(payload, this.user));
  }

  public customCookieSettings(payload: CookiePreference) {
    this.store.dispatch(new OpenCookieSettingsModal(payload, true, this.user));
  }

  public startQesCheck({
    provider,
    dateOfBirth
  }: {
    provider: QesMethods;
    dateOfBirth?: string;
  }) {
    this.store.dispatch(
      new fromState.StartQesCheck({
        provider,
        token: this.token,
        dateOfBirth
      })
    );
  }

  private handleDocusignResponse(state: string, url: string) {
    this.codeVerificationForm.get('aesWorkflowState').patchValue(state);
    switch (state) {
      case DigitalContractWorkflowState.EMBEDDED_SIGNING:
      case DigitalContractWorkflowState.AES_CODE_OK: {
        this.goToStep(DigitalContractWizardSteps.SIGNING);
        this.store.dispatch(new fromState.GoToDocuSign(url));
        break;
      }
      case DigitalContractWorkflowState.AES_CHECK: {
        this.goToStep(DigitalContractWizardSteps.ID_VERIFICATION);
        break;
      }
      case DigitalContractWorkflowState.VERIFY_CODE: {
        this.goToStep(DigitalContractWizardSteps.CODE_VERIFICATION);
        break;
      }
      case DigitalContractWorkflowState.QES_CHECK: {
        this.goToStep(DigitalContractWizardSteps.QES);
        break;
      }

      case DigitalContractWorkflowState.QES_OK: {
        this.store.dispatch(
          new fromState.GetSigningUrl(this.token, this.redirectUrl)
        );
        break;
      }
    }
  }

  /*
   * We call the status initially in onInit and also get status back from the startAesCheck and confirmAesData calls.
   * The returned aesStatus is handled here and routes to the correct wizard step.
   */
  private handleAesState(aesState: CurrentState, signatureType: SignatureType) {
    const { schufaState, itpState, signerState } = aesState;
    this.signerState = signerState;

    // allows form verification for ID check step and to show errors on ID check page
    this.idVerificationForm.get('aesSchufaState').patchValue(schufaState);
    this.idVerificationForm.get('aesItpState').patchValue(itpState);

    if (
      signerState === DigitalContractSignerState.INTERNAL_ITP_CODE_VERIFIED ||
      signerState === DigitalContractSignerState.DOCUSIGN_DELIVERED ||
      ((signatureType === SignatureType.ES_MAIL ||
        signatureType === SignatureType.AES_SMS) &&
        signerState ===
          DigitalContractSignerState.INTERNAL_FLAT_VISITED_UPDATED) ||
      signerState === DigitalContractSignerState.INTERNAL_SWISSCOM_TAC_SUCCESS
    ) {
      // case: PS closes docuSign window and needs to be redirect to docuSign again.
      this.goToStep(DigitalContractWizardSteps.SIGNING);
      this.store.dispatch(
        new fromState.GetSigningUrl(this.token, this.redirectUrl)
      );
    } else if (
      ((schufaState === SchufaVerificationState.SUCCESS ||
        schufaState === SchufaVerificationState.SUCCESS_AFTER_CONFIRMATION ||
        schufaState ===
          SchufaVerificationState.HISTORY_MUST_BE_IN_CORRECT_STATE) &&
        (itpState === DigitalContractItpState.INIT ||
          itpState === DigitalContractItpState.PENDING ||
          itpState === DigitalContractItpState.UPLOADED ||
          itpState === DigitalContractItpState.ACCEPTED)) ||
      (schufaState === SchufaVerificationState.SUCCESS &&
        itpState === DigitalContractItpState.TECHNICAL_ERROR)
    ) {
      this.goToStep(DigitalContractWizardSteps.CODE_VERIFICATION);
    } else if (
      schufaState ===
        SchufaVerificationState.SUCCESS_DATA_NEEDS_TO_BE_CONFIRMED ||
      schufaState === SchufaVerificationState.DATA_NEED_TO_BE_CONFIRMED_L ||
      itpState === DigitalContractItpState.FAILED ||
      itpState === DigitalContractItpState.TECHNICAL_ERROR ||
      itpState === DigitalContractItpState.UNKNOWN
    ) {
      // If not on step ID check, then go to that step.
      // Otherwise open the confirm dialog, if not already open
      // Initially we must not be both (go to step and open dialog), because we don't know the IBAN yet.
      if (
        this.getStepName(this.currentStepIndex) ===
          DigitalContractWizardSteps.ID_VERIFICATION &&
        !this.confirmAesModalOpen
      ) {
        this.openVerifySchufaDataModal(this.getIban);
      } else {
        this.goToStep(DigitalContractWizardSteps.ID_VERIFICATION);
      }
    } else if (
      schufaState ===
        SchufaVerificationState.DATA_NOT_CORRECT_AFTER_CONFIRMATION ||
      schufaState === SchufaVerificationState.CANCEL ||
      schufaState === SchufaVerificationState.ERROR ||
      (signatureType === SignatureType.AES_MAIL &&
        signerState ===
          DigitalContractSignerState.INTERNAL_FLAT_VISITED_UPDATED)
    ) {
      // if not already on ID check, then go to that step.
      // PS might close the dialog before calling confirmAesData endpoint.
      if (
        this.getStepName(this.currentStepIndex) !==
        DigitalContractWizardSteps.ID_VERIFICATION
      ) {
        this.goToStep(DigitalContractWizardSteps.ID_VERIFICATION);
      }
    } else if (
      schufaState ===
      SchufaVerificationState.SCHUFA_VERIFICATION_ALREADY_FINISHED
    ) {
      // This should not happen. If it does happen, we simply get the correct state and handle it
      // in this function (called by the selector in onInit).
      this.store.dispatch(new fromState.GetCurrentState(this.token));
    } else if (
      signatureType === SignatureType.QES &&
      (signerState ===
        DigitalContractSignerState.INTERNAL_FLAT_VISITED_UPDATED ||
        signerState === DigitalContractSignerState.INTERNAL_SWISSCOM_STARTED)
    ) {
      this.goToStep(DigitalContractWizardSteps.QES);
    } else if (
      signerState === DigitalContractSignerState.INTERNAL_SWISSCOM_SUCCESS
    ) {
      this.goToStep(
        DigitalContractWizardSteps.QES_MISSING_TERMS_AND_CONDITIONS
      );
    }
  }

  private sendTenantInfo(hasViewing: boolean, flatVisited?: Date) {
    const payload = {
      hasViewing,
      flatVisited,
      token: this.token,
      redirectUrl: this.redirectUrl
    };
    // return value will be handled by handleDocusignResponse
    this.store.dispatch(
      new fromState.SendTenantInfo(payload, this.contractSignatureType)
    );
  }

  private openVerifySchufaDataModal(iban: string) {
    this.confirmAesModalOpen = true;
    const modal = this.modalService.open<ConfirmIbanModalComponent>(
      ConfirmIbanModalComponent,
      { data: { iban } }
    );
    modal
      .onClose()
      .pipe(take(1))
      .subscribe(() => {
        this.confirmAesModalOpen = false;
      });
    modal
      .onDismiss()
      .pipe(take(1))
      .subscribe(() => {
        this.confirmAesModalOpen = false;
      });
  }

  private goToStep(stepName: DigitalContractWizardSteps) {
    const index = this.getWizardStepIndex(stepName);
    this.store.dispatch(new fromState.DigitalContractWizardGoToStep(index));
  }

  private redirectHome() {
    this.windowRef.open(this.environment.property_searcher_home_url, '_self');
  }

  private redirectToLandingPage() {
    this.windowRef.open(
      `${this.environment.property_searcher_base_url}/${this.translate.currentLang}/signContract?token=${this.token}`,
      '_self'
    );
  }

  private getBirthday(signers: Person[]) {
    return signers?.length === 1 && signers[0].dateOfBirth
      ? signers[0].dateOfBirth
      : null;
  }

  private getAllSteps(signatureType: SignatureType) {
    return SignContractStepsRoutes.filter(
      routeObject =>
        !routeObject.signatureType ||
        routeObject.signatureType === signatureType
    ).map((routeObject: any) => ({
      name: routeObject.path,
      nameNice: routeObject.data.title
    }));
  }
}
