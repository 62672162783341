import { Component, inject } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '@ui/legacy-lib';

@Component({
  selector: 'app-confirm-iban-modal',
  templateUrl: './confirm-iban-modal.component.html',
  styleUrls: ['./confirm-iban-modal.component.scss'],
  standalone: true,
  imports: [ComponentsModule, TranslateModule]
})
export class ConfirmIbanModalComponent {
  private ngbActiveModal = inject(NgbActiveModal);

  public iban: string;

  public cancel() {
    this.ngbActiveModal.dismiss();
  }

  public apply() {
    this.ngbActiveModal.close();
  }
}
